import PictureLazy from '@maison/components/common/PictureLazy';

export default {
  components: {
    PictureLazy,
  },

  props: {
    image: {
      type: Object,
      required: true,
    },
    hoverImage: {
      type: Object,
      default: null,
    },
    page: {
      type: Object,
      required: true,
    },
  },
};
