<script>
import CTA from '../common/CTA';
import MixedGridImageItem from '@maison/components/mixedGrid/MixedGridImageItem';
import MixedGridVideoItem from '@maison/components/mixedGrid/MixedGridVideoItem';

export default {
  components: {
    CTA,
    MixedGridImageItem,
    MixedGridVideoItem,
  },

  inheritAttrs: false,

  props: {
    cta: {
      type: Object,
      required: true,
    },
    image: {
      type: Object,
      required: true,
    },
    title: {
      type: String,
      required: true,
    },
    video: {
      type: Object,
      default: () => ({}),
    },
  },

  computed: {
    hasVideo() {
      return Object.keys(this.video).length !== 0;
    },
  },
};
</script>

<template>
  <div class="card card--sm" data-cy="mixed-grid-item">
    <mixed-grid-video-item v-if="hasVideo" v-bind="video" />
    <mixed-grid-image-item v-else :image="image" :is-sub-component="true" />

    <div class="card-body">
      <div class="h4">{{ title }}</div>

      <ul class="list list--mixed card-body__buttons max-sm:list--full">
        <li role="presentation">
          <c-t-a type="text" v-bind="cta" additional-classes="expand-target" />
        </li>
      </ul>
    </div>
  </div>
</template>
