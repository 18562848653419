<script>
import MixedGridVideoItem from '@phoenix/mixins/components/mixedGrid/MixedGridVideoItem';
import getTheLookPanel from '@maison/mixins/getTheLookPanel';
import { showPanel } from '@phoenix/helpers/panel-helper';
import { PhxIcon } from '@phx-styleguides/pia';

export default {
  components: { PhxIcon },

  mixins: [MixedGridVideoItem, getTheLookPanel],

  props: {
    isSubComponent: {
      default: false,
      type: Boolean,
    },
  },

  computed: {
    containerClasses() {
      const backgroundVideoClasses = ['video-player background-video media-card__media video-player--cover'];
      return this.isSubComponent ? ['card-media'].concat(backgroundVideoClasses) : backgroundVideoClasses;
    },
  },

  methods: {
    initVideo() {
      const backgroundVideo = this.$refs.video;
      if (backgroundVideo) {
        new Toolkit.BackgroundVideo(backgroundVideo, {
          classes: {
            loader: '.background-video__loader',
            controls: '.background-video__controls',
            play: '.background-video__icon--play',
            pause: '.background-video__icon--pause',
          },
        });
      }
    },

    showPanel,
  },
};
</script>

<template>
  <div class="media-card">
    <div ref="video" :class="containerClasses">
      <video class="background-video__video" :poster="poster" autoplay muted loop playsinline>
        <source :data-src="video" :type="sourceType" />
      </video>

      <button class="background-video__controls" :aria-label="$t('Accessibility.videoPlay')" type="button">
        <phx-icon icon="play" class="icon--xs background-video__icon background-video__icon--play" />
        <phx-icon icon="pause" class="icon--xs background-video__icon background-video__icon--pause" />
      </button>

      <div class="loader loader--light loader--sm background-video__loader">
        <div class="loader__inner"></div>
      </div>

      <div v-if="hasEcomPanel" class="media-card__actions" style="z-index: 1">
        <button
          class="btn btn--primary btn--icon-only-responsive btn--icon-only expand-target"
          type="button"
          @click="showPanel(ecomPanelId)"
        >
          <div class="btn__icon btn__icon--start">
            <phx-icon icon="shopping-bag" :title="$t('Accessibility.addToBag')" />
          </div>
        </button>
      </div>
    </div>
  </div>
</template>
