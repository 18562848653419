<script>
export default {
  props: {
    list: {
      type: Array,
      required: true,
    },
  },
};
</script>

<template>
  <div v-if="list.length > 0" data-cy="related-links">
    <div class="discover-more discover-more--center mt-base" role="presentation">
      <div class="discover-more__title">{{ $t('Product.related_pieces.discover_more') }}</div>
      <div class="discover-more__list-wrapper">
        <ul class="discover-more__list">
          <li v-for="item in list" :key="item.url" class="discover-more__list-item" data-cy="related-link">
            <a :href="item.url" class="btn btn--fade btn--xs">
              {{ item.title }}
            </a>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>
