<script>
import CTA from '@maison/components/common/CTA';
import MixedGridImageItem from '@maison/components/mixedGrid/MixedGridImageItem';
import MixedGridVideoItem from '@maison/components/mixedGrid/MixedGridVideoItem';
import MixedGridMediaItem from '@phoenix/mixins/components/mixedGrid/MixedGridMediaItem';

export default {
  components: {
    CTA,
    MixedGridImageItem,
    MixedGridVideoItem,
  },

  mixins: [MixedGridMediaItem],

  props: {
    cta: {
      default: null,
      type: Object,
      required: false,
    },
    isReversed: {
      default: false,
      type: Boolean,
    },
    media: {
      default: null,
      type: Object,
      required: false,
    },
    text: {
      type: String,
      default: null,
    },
    title: {
      type: String,
      default: '',
    },
  },

  computed: {
    containerClasses() {
      return ['card', 'card--horizontal', { 'card--reversed': this.isReversed }];
    },
  },
};
</script>

<template>
  <div :class="containerClasses">
    <mixed-grid-image-item
      v-if="media && media.image"
      v-bind="media"
      :is-sub-component="true"
      :item-id="itemId"
      :size="size"
    ></mixed-grid-image-item>
    <mixed-grid-video-item
      v-if="media && media.video"
      v-bind="media"
      :is-sub-component="true"
      :item-id="itemId"
      :size="size"
    ></mixed-grid-video-item>
    <div class="card-body">
      <div v-if="title" class="h4">
        {{ title }}
      </div>
      <div v-if="text" class="card-body__content">
        {{ text }}
      </div>
      <ul v-if="cta" class="list list--mixed card-body__buttons max-sm:list--full" role="presentation">
        <li role="presentation">
          <c-t-a v-bind="cta" :classes="['btn btn--bare btn--underline btn--fade btn--sm expand-target']"></c-t-a>
        </li>
      </ul>
    </div>
  </div>
</template>
