export default {
  props: {
    hasEcomPanel: {
      type: Boolean,
      default: false,
    },
    ecomPanelId: {
      type: String,
      default: null,
    },
  },
};
