<script>
import MixedGridImageItem from '@phoenix/mixins/components/mixedGrid/MixedGridImageItem';
import getTheLookPanel from '@maison/mixins/getTheLookPanel';
import { showPanel } from '@phoenix/helpers/panel-helper';
import { PhxIcon } from '@phx-styleguides/pia';

export default {
  components: { PhxIcon },

  mixins: [MixedGridImageItem, getTheLookPanel],

  props: {
    isSubComponent: {
      default: false,
      type: Boolean,
    },
    page: {
      default: () => ({}),
      type: Object,
      required: false,
    },
    additionalClasses: {
      type: Array,
      default: () => [],
    },
  },

  computed: {
    containerClasses() {
      return !this.hasPage && !this.hasEcomPanel ? ['card-media'] : ['media-card'];
    },

    pictureClasses() {
      return ['picture', 'media-card__media', 'picture--cover'].concat(this.additionalClasses);
    },

    hasPage() {
      return this.page && this.page.url;
    },
  },

  methods: {
    showPanel,
  },
};
</script>

<template>
  <component :is="hasPage ? 'a' : 'div'" :class="containerClasses" :href="hasPage ? page.url : null">
    <div :class="pictureClasses">
      <picture-lazy v-bind="image" img-class="lazyload--fade" />
    </div>
    <div v-if="hasEcomPanel" class="media-card__actions">
      <button
        class="btn btn--primary btn--icon-only-responsive btn--icon-only expand-target"
        type="button"
        data-cy="mixed-grid-get-the-look-btn"
        @click="showPanel(ecomPanelId)"
      >
        <div class="btn__icon btn__icon--start">
          <phx-icon icon="shopping-bag" :title="$t('Accessibility.addToBag')" />
        </div>
      </button>
    </div>
  </component>
</template>
