export default {
  props: {
    displayClasses: {
      type: Array,
      default() {
        return [];
      },
    },
    itemId: {
      type: String,
      required: true,
    },
    size: {
      type: Number,
      required: true,
    },
  },
};
