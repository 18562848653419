import MixedGridMediaItem from '@phoenix/mixins/components/mixedGrid/MixedGridMediaItem';

export default {
  mixins: [MixedGridMediaItem],

  props: {
    video: {
      type: String,
      required: true,
    },
    poster: {
      type: String,
      required: true,
    },
    sourceType: {
      type: String,
      required: true,
    },
  },

  mounted() {
    this.initVideo();
  },

  methods: {
    initVideo() {
      const video = document.querySelector(`#${this.itemId}`);
      if (video) {
        new Toolkit.BackgroundVideo(video);
      }
    },
  },
};
