<script>
import { map } from 'lodash-es';
import RelatedLinks from '../relatedLinks/RelatedLinks';
import Product from '../common/Product';
import MixedGridFilters from './MixedGridFilters';
import MixedGrid from '@phoenix/mixins/components/mixedGrid/MixedGrid';
import LoadMoreButton from '@maison/components/search/LoadMoreButton';
import MixedGridContentItem from './MixedGridContentItem';
import MixedGridPagePushItem from './MixedGridPagePushItem';
import MixedGridImageItem from './MixedGridImageItem';
import MixedGridVideoItem from './MixedGridVideoItem';
import CTA from '@maison/components/common/CTA.vue';
import { PhxPicture } from '@phx-styleguides/pia';

export default {
  components: {
    CTA,
    MixedGridFilters,
    PhxPicture,
    Product,
    RelatedLinks,
    LoadMoreButton,
    MixedGridPagePushItem,
    MixedGridImageItem,
    MixedGridVideoItem,
  },

  mixins: [MixedGrid],

  props: {
    alignedCenter: {
      type: Boolean,
      default: false,
    },
    headingTag: {
      type: String,
      default: 'h2',
    },
    relatedLinks: {
      type: Array,
      default: () => [],
    },
    shouldHideMediaItemsOnMobile: {
      type: Boolean,
      default: false,
    },
    ctas: {
      type: Array,
      default: () => [],
    },
  },

  data() {
    return {
      pageInnerWidth: window.innerWidth,
    };
  },

  computed: {
    shouldDisplayLoadMore() {
      return this.hasLoadMore && !this.areSortOrFiltersTouched && this.page < this.maxPage[this.pageSize];
    },

    pageSize() {
      if (this.pageInnerWidth >= 1025) {
        return 'lg';
      }
      if (this.pageInnerWidth >= 745) {
        return 'md';
      }
      if (this.pageInnerWidth >= 516) {
        return 'sm';
      }
      return 'xs';
    },

    /**
     * Available widths for each breakpoints
     */
    widths() {
      return {
        lg: {
          1: '1',
          2: '2',
          4: '4',
        },
        md: {
          1: '1',
          2: '2',
        },
        sm: {
          1: '1',
          2: '2',
        },
        xs: {
          1: '1',
          2: '2',
        },
      };
    },
  },

  created() {
    window.addEventListener('resize', () => (this.pageInnerWidth = window.innerWidth));
  },

  methods: {
    getComponent(template) {
      switch (template) {
        case 'product':
          return Product;
        case 'image-fit':
          return MixedGridImageItem;
        case 'video-item':
          return MixedGridVideoItem;
        case 'push-item':
        case 'push-video-item':
          return MixedGridPagePushItem;
        case 'content-item':
          return MixedGridContentItem;
      }
    },

    getDisplayedColumnClasses(column) {
      if (!this.shouldDisplayLoadMore) {
        return;
      }

      return map(column.page, (page, size) => {
        const prefix = size !== 'xs' ? `${size}:` : '';
        return this.page < page ? `${prefix}hidden` : '';
      });
    },

    /**
     * Construct the classes used to define the sizes of columns for each breakpoints.
     */
    getSizes(column) {
      const prefix = 'mixed-grid__item';

      if (this.areSortOrFiltersTouched) {
        return `${prefix}--1/2 lg:${prefix}--1/4`;
      }

      const lg = column.sizes.lg ? column.sizes.lg : 1;
      const xs = column.sizes.xs ? column.sizes.xs : 1;

      return `${prefix}--${this.widths.xs[xs]}/2 lg:${prefix}--${this.widths.lg[lg]}/4`;
    },

    getColumnClasses(column) {
      return [
        'mixed-grid__item',
        this.getSizes(column),
        {
          'sm:hidden-down': column.template !== 'product' && this.shouldHideMediaItemsOnMobile,
        },
        this.getDisplayedColumnClasses(column),
      ];
    },

    getColumnData(column) {
      const data = { ...column.data };
      if (column.template === 'product') {
        data.disableWideStyle = this.areSortOrFiltersTouched;
        data.dataTracking = this.getDataTracking(data);
      }
      return data;
    },
  },
};
</script>

<template>
  <div>
    <mixed-grid-filters
      v-if="displayFilter"
      :filters="filtersWithLocalizedPrices"
      :empty-filters="emptyFilters"
      :products-count="filteredColumnsCount"
      :unique-id="uniqueId"
      :default-sort="defaultSort"
      :default-filters="activeFilters"
      :should-display-sellable="shouldDisplaySellable"
      :has-filters-in-url="hasFiltersInUrl"
      @on-sort-change="onSortChange"
      @on-filters-changed="onFiltersChanged"
    />
    <div class="container container--lg">
      <div v-if="title" class="headings headings--centered">
        <component :is="headingTag" class="h2">{{ title }}</component>
        <h3 v-if="subtitle" class="headings__subtitle">{{ subtitle }}</h3>
      </div>
      <div ref="grid" data-cy="mixed-grid" :class="['mixed-grid', { 'mixed-grid--center': alignedCenter }]">
        <div v-for="column in filteredColumns" :key="column.data.itemId" :class="getColumnClasses(column)">
          <component
            :is="getComponent(column.template)"
            v-bind="getColumnData(column)"
            :sizes="column.sizes"
            data-cy="mixed-grid-item"
            :data-cy-item="column.template"
          >
          </component>
        </div>
      </div>
      <div v-if="shouldDisplayLoadMore || ctas.length" class="mixed-grid__actions">
        <ul v-if="shouldDisplayLoadMore" class="actions actions--fluid" role="presentation">
          <li role="presentation">
            <load-more-button
              :classes="'btn btn--tertiary actions--fluid'"
              data-cy="mixed-grid-load-more"
              @click="nextPage"
            />
          </li>
          <li role="presentation">
            <button data-cy="mixed-grid-see-all" class="btn btn--tertiary actions--fluid" type="button" @click="seeAll">
              {{ $t('Button.seeAll') }}
            </button>
          </li>
        </ul>
        <c-t-a v-for="(cta, index) in ctas" v-else :key="index" v-bind="cta" :class="{ 'mrgl-': index === 1 }" />
      </div>
      <related-links :list="relatedLinks" />
    </div>
  </div>
</template>
